import {
  SurveyURLId,
  useSurveyURLId,
} from "@/sleep_compass_lite/components/target/greetings/hooks";
import { useThrowError } from "@/sleep_compass_lite/components/target/LiteErrorBoundary";
import { authenticationPath } from "@/sleep_compass_lite/domain_models/target/AuthenticationPath";
import { useEffect } from "react";
import { useNavigate, useParams } from "react-router-dom";

export function RedirectToURLGreetings() {
  const { redirectId } = useParams();
  const redirectURLIdService = useSurveyURLId();
  const navigate = useNavigate();
  const throwError = useThrowError();

  useEffect(() => {
    (async () => {
      if (redirectId == null) return;
      try {
        const res = await redirectURLIdService.get(redirectId);
        const surveyURLId = res.data as SurveyURLId;
        if (surveyURLId.url_id != null) {
          navigate(
            authenticationPath.getFullPath("URLGreetings", {
              ":urlId": surveyURLId.url_id,
            })
          );
        }
      } catch (e: unknown) {
        throwError("survey url_id not found");
      }
    })();
  }, [redirectURLIdService, redirectId, navigate, throwError]);

  return <></>;
}
