import { NonFieldError } from "@/components/common/SCAlert";
import { SubtitleTypography } from "@/components/common/Typographies";
import { PX4, PX6 } from "@/sleep_compass_lite/components/target/StackStyles";
import Expired from "@/sleep_compass_lite/components/target/greetings/Expired";
import {
  URLInformation,
  useSurveyInfoRegisterable,
  useURLInformation,
} from "@/sleep_compass_lite/components/target/greetings/hooks";
import {
  AuthenticationPages,
  authenticationPath,
} from "@/sleep_compass_lite/domain_models/target/AuthenticationPath";
import {
  Box,
  Button,
  Card,
  CardContent,
  Stack,
  Typography,
  TypographyProps,
} from "@mui/material";
import { ReactNode, useEffect, useState } from "react";
import { Link as RouterLink, useParams } from "react-router-dom";
import { UAParser } from "ua-parser-js";

/**
 * 表示するカードの内容
 */
const CARD_CONTENT: { title: string; body: string; caption?: string }[] = [
  {
    title: "規約同意・アカウントの作成",
    body: "「いますぐチェック開始」から利用規約に同意し、新しいアカウントを作成してください。過去にSLEEP COMPASS Lightを利用したことがある方は、その際に作成したアカウントを利用してログインしてください。",
  },
  {
    title: "事前問診に回答",
    body: "あなたの身長・体重や、睡眠に関する一般的な質問など、調査をはじめるにあたっての基本的な質問にお答えください。",
  },
  {
    title: "3日分の問診に回答",
    body: "事前問診完了後、日中の状態と前日の睡眠について回答いただくためのメールが1週間程度配信されます※。そのうち3日間回答すると、今回のチェックは完了です。",
    caption:
      "※メールは事前問診完了の当日または翌日から配信開始されます。（問診の完了タイミングによって異なります）",
  },
  {
    title: "レポートの確認",
    body: "3日分の回答が完了すると、チェック結果のレポートが閲覧可能になります。今後の睡眠改善にお役立てください。",
  },
];

type IdType = "surveyInfoUid" | "urlId";

interface CommonGreetingsProps {
  type: IdType;
  id?: string;
  title?: ReactNode;
}
function CommonGreetings({ type, id, title }: CommonGreetingsProps) {
  const parser = new UAParser(window.navigator.userAgent);
  const parserResults = parser.getResult();
  const isSupportBrowser = ["Chrome", "Safari", "Edge"].some((b) =>
    parserResults.browser.name?.includes(b)
  );
  return (
    <>
      {!isSupportBrowser && (
        <Stack spacing={8} sx={{ px: PX4, pt: 4 }} alignItems="center">
          <NonFieldError>
            お使いのブラウザは推奨ブラウザではありません、正しく動作しない可能性があるため、推奨ブラウザでのアクセスをお勧めします。
            <br></br>
            推奨ブラウザ: Google Chrome, Safari, Microsoft Edge
          </NonFieldError>
        </Stack>
      )}
      {title != null && title}
      <Stack sx={{ px: PX6, pt: 10, pb: 20, gap: 10 }}>
        <Stack sx={{ gap: 2 }}>
          <Typography
            sx={{
              color: "primary.main",
              textAlign: "center",
              fontFeatureSettings: "'palt' on, 'clig' off, 'liga' off",
              fontFamily: "Noto Sans JP",
              fontSize: "34px",
              fontStyle: "normal",
              fontWeight: 500,
              lineHeight: "150%",
              letterSpacing: "0.25px",
            }}
          >
            眠りの知識が<br></br>毎日を変える
          </Typography>
          <Typography
            variant="body2"
            sx={{
              color: "text.primary",
              textAlign: "center",
              fontFeatureSettings: "'palt' on, 'clig' off, 'liga' off",
            }}
          >
            3日間、毎日の終わりに問診に答えるだけ<br></br>
            睡眠・パフォーマンスを気軽にチェック！
          </Typography>
        </Stack>
        <GreetingsImage />
        <Stack sx={{ gap: 4 }}>
          <Stack sx={{ gap: 2 }}>
            <Typography
              sx={{
                color: "primary.main",
                textAlign: "center",
                fontFeatureSettings: "'palt' on, 'clig' off, 'liga' off",
                alignSelf: "stretch",
              }}
            >
              \ はじめての方はこちら /
            </Typography>
            <Stack sx={{ gap: 4 }}>
              <StartButton type={type} id={id} />
              <LoginButton type={type} id={id} />
            </Stack>
          </Stack>
        </Stack>
      </Stack>
      <Stack
        sx={{
          gap: 16,
          px: PX6,
          pt: 10,
          pb: 20,
          backgroundColor: "primary.dark",
        }}
      >
        <Stack sx={{ gap: 6 }}>
          <SubtitleTypography
            borderColor="primary.light"
            variant="h5"
            sx={{
              color: "#FFFFFF",
              fontFeatureSettings: "'palt' on, 'clig' off, 'liga' off",
            }}
          >
            睡眠を知ることから始めよう
          </SubtitleTypography>
          <Stack sx={{ gap: 4 }}>
            <ContrastTypography>
              睡眠はあなたの日中の生産性、注意力やメンタルに大きな影響を与えています。
            </ContrastTypography>
            <ContrastTypography>
              「SLEEP COMPASS Light」は、睡眠健康測定サービス「SLEEP
              COMPASS」の簡易版です。
            </ContrastTypography>
            <ContrastTypography>
              手軽に受けられる3日間の問診で、あなたの睡眠・パフォーマンスを可視化。睡眠課題の有無を把握でき、次の打ち手に繋げます。
            </ContrastTypography>
          </Stack>
        </Stack>
        <KnowYourSleepImage />
      </Stack>
      <Stack sx={{ gap: 6, px: PX6, pt: 10, pb: 20 }}>
        <SubtitleTypography
          sx={{
            color: "text.primary",
            fontFeatureSettings: "'palt' on, 'clig' off, 'liga' off",
          }}
        >
          この後の流れ
        </SubtitleTypography>
        {CARD_CONTENT.map((c, i) => ({
          no: `${i + 1}`.padStart(2, "0"),
          ...c,
        })).map((c, i) => (
          <Card
            key={i}
            sx={{
              borderRadius: "16px",
              backgroundColor: "#FFFFFF",
              boxShadow: "0px 0px 0px 1px #E0E0E0",
            }}
          >
            <img
              src={`/img/image_lite_greetings_next_step_${c.no}.png`}
              alt={`step_${c.no}`}
              width="100%"
              height="auto"
            />
            <CardContent sx={{ "&.MuiCardContent-root": { p: 4 } }}>
              <Stack sx={{ gap: 2 }}>
                <Typography
                  variant="subtitle2"
                  sx={{
                    color: "primary.main",
                    fontFeatureSettings: "'palt' on, 'clig' off, 'liga' off",
                  }}
                >
                  STEP{c.no}
                </Typography>
                <Typography
                  variant="h6"
                  sx={{
                    color: "text.primary",
                    fontFeatureSettings: "'palt' on, 'clig' off, 'liga' off",
                  }}
                >
                  {c.title}
                </Typography>
                <Typography
                  variant="body1"
                  sx={{
                    color: "text.primary",
                    fontFeatureSettings: "'palt' on, 'clig' off, 'liga' off",
                  }}
                >
                  {c.body}
                </Typography>
              </Stack>
              {c.caption != null && (
                <Typography variant="caption">{c.caption}</Typography>
              )}
            </CardContent>
          </Card>
        ))}
        <Stack>
          <Stack sx={{ gap: 4, pt: 4 }}>
            <StartButton type={type} id={id} />
            <LoginButton type={type} id={id} />
          </Stack>
        </Stack>
      </Stack>
    </>
  );
}

/**
 * 登録開始ページ
 */
export function Greetings() {
  const { surveyInfoUid } = useParams();
  const [isExpired, setExpired] = useState(false);
  const service = useSurveyInfoRegisterable();

  useEffect(() => {
    (async () => {
      if (surveyInfoUid == null) return;
      setExpired(await service.isExpired(surveyInfoUid));
    })();
  }, [service, surveyInfoUid]);

  if (isExpired) {
    return <Expired />;
  }
  return <CommonGreetings type="surveyInfoUid" id={surveyInfoUid} />;
}
export function URLGreetings() {
  const { urlId } = useParams();
  const urlInformationService = useURLInformation();
  const [urlInformation, setURLInformation] = useState<URLInformation>();
  useEffect(() => {
    (async () => {
      if (urlId == null) return;
      const res = await urlInformationService.get(urlId);
      setURLInformation(res.data);
    })();
  }, [urlInformationService, urlId]);

  if (urlInformation == null) {
    return <></>;
  }
  if (!urlInformation.is_registerable_time) {
    return <Expired />;
  }

  return (
    <CommonGreetings
      type="urlId"
      id={urlId}
      title={
        urlInformation?.greetings_title == null ? null : (
          <Stack sx={{ backgroundColor: "success.light" }}>
            <Typography
              variant="subtitle1"
              sx={{ color: "#FFFFFF", textAlign: "center", my: "8px" }}
            >
              {urlInformation?.greetings_title}
            </Typography>
          </Stack>
        )
      }
    />
  );
}

/**
 * 登録開始ページのファーストビューコンポーネント
 * @returns 画像コンポーネント
 */
function GreetingsImage() {
  return (
    <Box px="23.5px">
      <img
        src="/img/image_lite_greetings_01.png"
        alt="SLEEP COMPASS Light"
        loading="lazy"
        style={{
          width: "100%",
          height: "auto",
          maxWidth: "420px",
        }}
      />
    </Box>
  );
}

/**
 * 睡眠を知ることから始めようの画像コンポーネント
 * @returns 画像コンポーネント
 */
function KnowYourSleepImage() {
  return (
    <img
      src="/img/image_lite_greetings_know_your_sleep.png"
      alt="know your sleep"
      loading="lazy"
      style={{
        width: "100%",
        margin: "auto",
        display: "block",
        maxWidth: "490.5px",
      }}
    />
  );
}

/**
 * 背景が `primary.dark` の場合の {@link Typography} です。
 * @param props {@link TypographyProps}
 * @returns 背景が `primary.dark` の場合の {@link Typography}
 */
function ContrastTypography(props: TypographyProps) {
  return (
    <Typography
      variant="body1"
      sx={{
        color: "#FFFFFF",
        textAlign: "justify",
        fontFeatureSettings: "'palt' on, 'clig' off, 'liga' off",
      }}
      {...props}
    />
  );
}

/**
 * 今すぐ開始と、ログインボタン共通のプロパティ
 */
interface SurveyButtonProps {
  type: IdType;
  id: string | undefined;
}
/**
 * 今すぐ開始のボタン
 *
 * @param {SurveyButtonProps} Liteサーベイ情報IDのプロパティ
 * @returns 今すぐ開始のボタン
 */
function StartButton({ type, id }: SurveyButtonProps) {
  const config: Record<
    IdType,
    Extract<AuthenticationPages, "SignUp" | "URLSignUp">
  > = {
    surveyInfoUid: "SignUp",
    urlId: "URLSignUp",
  };
  return (
    <Button
      variant="contained"
      sx={{
        boxShadow: "none",
        borderRadius: "8px",
        backgroundColor: "primary.main",
      }}
      component={RouterLink}
      to={authenticationPath.getFullPath("TermsOfServiceAgreement")}
      state={getTermsOfServiceAgreementState({
        type,
        id,
        page: config[type],
      })}
    >
      いますぐチェック開始
    </Button>
  );
}

/**
 * ログインボタン
 *
 * @param {SurveyButtonProps} Liteサーベイ情報IDのプロパティ
 * @returns ログインボタン
 */
function LoginButton({ type, id }: SurveyButtonProps) {
  const config: Record<
    IdType,
    Extract<AuthenticationPages, "SignIn2" | "URLSignIn">
  > = {
    surveyInfoUid: "SignIn2",
    urlId: "URLSignIn",
  };
  return (
    <Button
      variant="outlined"
      sx={{
        color: "primary.main",
        fontFeatureSettings: "'clig' off, 'liga' off",
      }}
      component={RouterLink}
      to={authenticationPath.getFullPath("TermsOfServiceAgreement")}
      state={getTermsOfServiceAgreementState({ type, id, page: config[type] })}
    >
      ログイン
    </Button>
  );
}

function getTermsOfServiceAgreementState({
  type,
  id,
  page,
}: {
  type: IdType;
  id: string | undefined;
  page: AuthenticationPages;
}) {
  return {
    surveyInfoUid: {
      surveyInfo: id,
      nextPath: authenticationPath.getFullPath(page),
    },
    urlId: {
      urlId: id,
      nextPath: authenticationPath.getFullPath(page),
    },
  }[type];
}
