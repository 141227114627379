import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Stack,
  Typography,
} from "@mui/material";
import { useState } from "react";
import { ReportImg } from "../commons/ReportImg";
import SleepDiaryChart from "../commons/SleepDiaryChart";
import Stepper from "../commons/Stepper";
import { TopResponse } from "../types";

function ReportDialogPage1(props: {
  next: () => void;
  step: () => number;
  activeStep: number;
}) {
  return (
    <>
      <DialogTitle
        sx={{
          borderBottom: "1px solid rgba(0, 0, 0, 0.12)",
          padding: 0,
        }}
      >
        <Stack
          direction="row"
          sx={{
            height: "61px",
            paddingX: "16px",
            alignItems: "center",
            justifyContent: "space-between",
          }}
        >
          <Typography
            sx={{ fontSize: "16px", fontWeight: 500, color: "#003C70" }}
          >
            レポートを確認する前に
          </Typography>
          <Stack
            direction="row"
            sx={{ marginLeft: "auto", gap: "8px", alignItems: "center" }}
          >
            <Stepper step={props.step()} activeStep={props.activeStep} />
          </Stack>
        </Stack>
      </DialogTitle>
      <DialogContent sx={{ height: "328px", padding: "16px" }}>
        <Typography
          textAlign="justify"
          sx={{ fontSize: "14px", lineHeight: "25.9px", color: "#3D3D3D" }}
        >
          本レポートで提供される情報や結果は、病気の診断や治療を意図するものではありません。
          <br />
          診断ガイドラインに基づく問診回答により、睡眠時無呼吸症候群・不眠症の疾患リスクを表示する場合がありますが、正しく問診回答が行われなかった場合、正しい結果が出ない場合があります。
          <br />
          結果に関わらず、気になることがある場合には、かかりつけ医・睡眠の専門医に相談することをお勧めします。
        </Typography>
        <Typography
          textAlign="justify"
          sx={{
            fontSize: "14px",
            lineHeight: "25.9px",
            color: "#3D3D3D",
            textDecoration: "underline",
            marginTop: "16px",
          }}
        >
          最初に、今回あなたが測定した睡眠のデータについて次のページで確認してから、レポートの確認に進みましょう。
        </Typography>
      </DialogContent>
      <DialogActions sx={{ height: "68px", padding: 0 }}>
        <Button
          variant="outlined"
          sx={{
            width: "107px",
            borderRadius: "18px",
            height: "36px",
            marginX: "auto",
          }}
          onClick={() => props.next()}
        >
          つぎへ
        </Button>
      </DialogActions>
    </>
  );
}

function ReportDialogPage2(props: {
  data: TopResponse;
  next: () => void;
  step: () => number;
  activeStep: number;
}) {
  return (
    <>
      <DialogTitle
        sx={{
          borderBottom: "1px solid rgba(0, 0, 0, 0.12)",
          padding: 0,
        }}
      >
        <Stack
          direction="row"
          sx={{
            height: "61px",
            paddingX: "16px",
            alignItems: "center",
            justifyContent: "space-between",
          }}
        >
          <Typography
            sx={{ fontSize: "16px", fontWeight: 500, color: "#003C70" }}
          >
            あなたの測定データ
          </Typography>
          <Stack
            direction="row"
            sx={{ marginLeft: "auto", gap: "8px", alignItems: "center" }}
          >
            <Stepper step={props.step()} activeStep={props.activeStep} />
          </Stack>
        </Stack>
      </DialogTitle>
      <DialogContent sx={{ padding: "16px" }}>
        <Stack direction="row" justifyContent="end" spacing={3}>
          <Stack direction="row" sx={{ gap: "4px" }}>
            <ReportImg image_name="image_report_how_to_view_graphs_light_off.svg" />
            <Typography variant="body2" sx={{ fontSize: "12px" }}>
              消灯
            </Typography>
          </Stack>
          <Stack direction="row" sx={{ gap: "4px" }}>
            <ReportImg image_name="image_report_how_to_view_graphs_wake_up.svg" />
            <Typography variant="body2" sx={{ fontSize: "12px" }}>
              起床
            </Typography>
          </Stack>
          <Stack direction="row" sx={{ gap: "4px" }}>
            <ReportImg image_name="image_report_how_to_view_graphs_sleep.svg" />
            <Typography variant="body2" sx={{ fontSize: "12px" }}>
              睡眠
            </Typography>
          </Stack>
          <Stack direction="row" sx={{ gap: "4px" }}>
            <ReportImg image_name="image_report_how_to_view_graphs_mid-awakening.svg" />
            <Typography variant="body2" sx={{ fontSize: "12px" }}>
              睡眠中の覚醒
            </Typography>
          </Stack>
          <Stack direction="row" sx={{ gap: "4px" }}>
            <ReportImg image_name="image_report_how_to_view_graphs_nap.svg" />
            <Typography variant="body2" sx={{ fontSize: "12px" }}>
              仮眠
            </Typography>
          </Stack>
        </Stack>
        <Stack sx={{ height: "309px", marginTop: "16px" }}>
          <SleepDiaryChart
            data={props.data.summary.chart_data.map((d) => ({
              date: d.date,
              bedtime: new Date(d.bedtime),
              ariseTime: new Date(d.arise_time),
              sleepData: d.sleep_data.map((d) => ({
                from: new Date(d.from),
                to: new Date(d.to),
                type: d.type,
              })),
            }))}
          />
        </Stack>
        <Stack sx={{ marginTop: "16px" }}>
          <Typography
            sx={{
              fontSize: "12px",
              textAlign: "center",
              color: "#707070",
              lineHeight: "21px",
            }}
          >
            ※このデータは、レポート内でも詳細を確認できます。
          </Typography>
        </Stack>
      </DialogContent>
      <DialogActions sx={{ height: "68px", padding: 0 }}>
        <Button
          variant="outlined"
          sx={{
            width: "165px",
            borderRadius: "18px",
            height: "36px",
            marginX: "auto",
            backgroundColor: "#0056A0",
            fontSize: "14px",
            color: "white",
          }}
          onClick={() => props.next()}
        >
          レポートを見る
        </Button>
      </DialogActions>
    </>
  );
}

/**
 * {@link BeforeCheckReportDialog} のプロパティ
 */
export interface BeforeCheckReportDialogProps {
  /** ダイアログを開く */
  open: boolean;
  /** ダイアログを閉じる */
  close: () => void;
  data: TopResponse;
}
/**
 * レポートを確認する前にダイアログ
 * @param param0 {@link BeforeCheckReportDialogProps}
 * @returns レポートを確認する前にダイアログ
 */
export function BeforeCheckReportDialog({
  open,
  close,
  data,
}: BeforeCheckReportDialogProps) {
  const [display, setDisplay] = useState(0);
  const dialogs: JSX.Element[] = [];

  function next() {
    if (dialogs.length === display + 1) {
      setDisplay(0);
      return close();
    }
    setDisplay((display) => display + 1);
  }

  dialogs.push(
    <ReportDialogPage1
      next={next}
      step={() => dialogs.length}
      activeStep={dialogs.length + 1}
    />
  );
  dialogs.push(
    <ReportDialogPage2
      data={data}
      next={next}
      step={() => dialogs.length}
      activeStep={dialogs.length + 1}
    />
  );

  return (
    <Dialog
      open={open}
      sx={{ marginX: "auto" }}
      PaperProps={{
        sx: {
          borderRadius: "16px",
          alignSelf: "start",
          marginTop: "4em",
          width: "343px",
        },
      }}
    >
      {dialogs[display]}
    </Dialog>
  );
}
